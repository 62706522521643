import React, { useContext } from 'react'
import Header from '../components/Header'
import { ThemeContext } from '../ThemeContext'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import { BsCheck2Circle } from "react-icons/bs"
import { MdOutlineContactSupport } from "react-icons/md"
import { AiFillHome } from "react-icons/ai";

export default function Checkout() {
    const {themes} = useContext(ThemeContext)

    const [totalPrice, name, phone] = [window.localStorage.getItem('totalPrice'), JSON.parse(window.localStorage.getItem('name')), JSON.parse(window.localStorage.getItem('phone'))]

    return (
        <div id={themes}>
            <Header />
            <Container className='text-center my-5'>
                <Row className='d-flex align-items-center' xs={1} md={2}>
                    <Col md={8} className='p-3 mb-5'>
                        <div className='py-3'><BsCheck2Circle size={50} color='green'/></div>
                        <h3 className='fw-bold py-2'>Thank you for your purchase</h3>
                        <div id='themesSubscriptColor'>You should receive an order comfirmation SMS shortly.</div>
                        <div id='themesSubscriptColor'>Phone Number: {phone}</div>
                    </Col>
                    <Col className='mb-5 p-5' id='deliveryWrapper' md={4}>
                        <div className='py-3'><MdOutlineContactSupport size={35} /></div>
                        <h3 className='fw-bold'>Contact Us</h3>
                        <div>Phone: 9988 6677</div>
                        <div>Email: jstore@example.com</div>
                        
                    </Col>
                </Row>
            </Container>
            <div className='d-flex justify-content-center my-5'><Link to='/'><button id='homeButton'><AiFillHome size={20} /></button></Link></div>
            <Footer/>
        </div>
    )
}
