import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { CartContext } from '../CartContext'


export default function AddCartBtn({productInfo}) {
    const {cartItems, setCartItems} = useContext(CartContext)

    let productIndexInCart = cartItems.findIndex((element)=>{
        return element.id === productInfo.id;
        })

    const [countNumber, setCountNumber] = useState(productIndexInCart === -1 ? 0 : cartItems[productIndexInCart].quantity) 

    const addToCart=()=>{
        if (productIndexInCart === -1){
            setCartItems([...cartItems, {
                id: productInfo.id,
                name: productInfo.name,
                price: productInfo.price,
                image: productInfo.image,
                quantity: 1,
                description: productInfo.description
            }])
        }else{
            let newcartItems = [...cartItems]
            newcartItems[productIndexInCart].quantity++
            setCartItems(newcartItems)
        };
        setCountNumber(countNumber+1);
    }


    return (
        <div> 
            <Button variant="outline-success" onClick={addToCart}>{countNumber === 0 ? 'Add to Cart' : 'Quantity: '+ countNumber}</Button>
        </div>
    )
}
