import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import icon from '../img/icon.png'
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

export default function Footer() {

    return (
        <>
            <hr/>
            <Row className='g-0 text-center d-flex align-items-center' xs={1} md={2}>
                <Col className='mb-3'>
                    <img src={icon} width={450}/>
                </Col>
                <Col className='mb-3'>
                    <h3>Contact Us</h3>
                    <ui style={{listStyle:'none'}}>
                        <li className='py-3'><a href='https://www.facebook.com/'><BsFacebook size={30} style={{ color:'rgb(131, 158, 245)'}} /></a></li>
                        <li className='py-3'><a href='https://www.instagram.com/'><BsInstagram size={30} style={{ color: 'rgb(230, 131, 177)' }} /></a></li>
                        <li className='py-3'><a href='https://twitter.com/'><BsTwitter size={30} style={{ color: 'rgb(49, 216, 49)' }} /></a></li>
                    </ui>
                </Col>
            </Row>
            <div className="text-center pt-5 pb-3">@2022 Copyright: Joe NG Personal</div>
        </>
    )
}
