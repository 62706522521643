import React, { useContext } from 'react'
import { CartContext } from '../CartContext'
import Button from 'react-bootstrap/Button'
import { Link, useNavigate } from 'react-router-dom'
import { ThemeContext } from '../ThemeContext'
import Header from '../components/Header'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/esm/Container'
import {IoChevronBack} from "react-icons/io5"
import Footer from '../components/Footer'
import video from '../img/funVideo.mp4'
import Payment from '../components/Payment'



export default function CartPage() {
    const { themes } = useContext(ThemeContext)
    const navigate = useNavigate()

    let { cartItems, setCartItems } = useContext(CartContext)

    const cartEmpty = cartItems.length <= 0


    return (
        <div id={themes}>
        <Header/>
        <Link to='/'><Button variant="outline-success" className='ms-3 mb-4'><IoChevronBack /></Button></Link>
{/*         {!cartEmpty?<div>
            {cartItems.map(product=>(
                <React.Fragment key={product.id}>
                    <img src={process.env.PUBLIC_URL + '/img/' + product.image}/>
                    <p>Name:{product.name}</p>
                    <p>Price:{product.price}</p>
                    <p>Quantity:{product.quantity}</p>
                    <p><Button variant="warning" onClick={()=>{
                        let productIndexInCart = cartItems.findIndex((element) => { return element.id === product.id})
                        let newcartItems = [...cartItems]
                        if (newcartItems[productIndexInCart].quantity === 1) { newcartItems.splice(productIndexInCart, 1); setCartItems(newcartItems)}
                        else {newcartItems[productIndexInCart].quantity--; setCartItems(newcartItems)}
                    }}>Remove in Cart</Button></p>
                </React.Fragment>
            ))}
        </div>:
        <div>No items in Shopping<p>Go to Shop</p></div>} */}

        <Container>
            {!cartEmpty? 
            cartItems.map(product=>(
                <React.Fragment key={product.name}>
                <Row  xs={1} md={2} className="d-flex justify-content-between align-items-center mb-4 border p-3 my-5" key={product.id}>
                    <Col lg={4}>
                        <Image src={process.env.PUBLIC_URL + '/img/' + product.image} style={{width:'100%'}}/>
                    </Col>

                        <Col className='text-center'>
                        <ui style={{listStyle:'none'}}>
                            <li className='my-3 fw-bold fs-4'>{product.name}</li>
                            <li className='my-3'>Price:$ {product.price}</li>
                            <li className='my-3'>Quantity: {product.quantity}</li>
                            <li className='my-3'>Total:$ {product.quantity * product.price}</li>
                        </ui>
                        <div>
                            <Button variant="warning" onClick={() => {
                                let productIndexInCart = cartItems.findIndex((element) => { return element.id === product.id })
                                let newcartItems = [...cartItems]
                                if (newcartItems[productIndexInCart].quantity === 1) { newcartItems.splice(productIndexInCart, 1); setCartItems(newcartItems) }
                                else { newcartItems[productIndexInCart].quantity--; setCartItems(newcartItems) }
                                }}>{product.quantity === 1?'Remove in Cart':'-'}</Button>
                        </div>
                    </Col>
                </Row>
                </React.Fragment>))
                :<div className='border rounded p-3 text-center'>
                    <h3 className='py-3'>Nothing in Shopping Cart</h3>

                    <div className='py-3'><video src={video} width='60%' controls={true}/></div>

                    <Link to='/'><Button variant="info" className='mb-3'>Go to Shop</Button></Link>
                </div>
            }

            {!cartEmpty?<Payment/>:''}

        </Container>
        <Footer/>
        </div>
    )
}
