import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useState } from 'react';
import { ThemeContext } from './ThemeContext';
import ProductList from './pages/ProductList';
import { CartContext } from './CartContext';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import CartPage from './pages/CartPage';
import ProductDetail from './pages/ProductDetail';
import Checkout from './pages/Checkout';




function App() {


  const [themes, setThemes] = useState('light')
  const toggleThemes = () => setThemes((curr) => curr === "light" ? "dark" : "light")

  const [cartItems, setCartItems] = useState([])


  return (
    <BrowserRouter>

    <ThemeContext.Provider value={{themes, toggleThemes}}>
      <CartContext.Provider value={{cartItems, setCartItems}}>

        <Routes>
            
            <Route path='/' element={<ProductList />} />

            <Route path='/productDetail' element={<ProductDetail/>}>
              <Route path=':id' element={<ProductDetail />}/>
            </Route>
            
            <Route path='/shoppingCart' element={<CartPage/>}/>

            <Route path='/checkoutReview' element={<Checkout />}/>
            
            <Route path='*' element={<h1>404</h1>}/>
          
        </Routes>

      </CartContext.Provider>
    </ThemeContext.Provider>

    </BrowserRouter>
    
  )
}

export default App;
