import React, { useContext, useEffect, useState } from 'react'
import AddCartBtn from '../components/AddCartBtn'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../ThemeContext'
import Header from '../components/Header'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { RiShoppingCart2Line, RiShoppingCart2Fill } from "react-icons/ri"
import { CartContext } from '../CartContext'
import Footer from '../components/Footer'

export default function ProductList() {
    const {cartItems} = useContext(CartContext)
    const { themes } = useContext(ThemeContext)
    const [productList, setProductList] = useState([])

    const cartEmpty = cartItems.length <= 0
    

    useEffect(()=>{
        fetch('https://joe-kaiwai.github.io/API/photography_api.json')
            .then(res => res.json())
            .then(data => setProductList(data))
    },[]
    )

    return (
        <div id={themes}>
            <Header/>
            <Container> 
                <div className='d-flex justify-content-center mt-5'><Link to='/shoppingCart'>{!cartEmpty ?<RiShoppingCart2Fill id="themeIconName" size={30}/>:<RiShoppingCart2Line id="themeIconName" size={35} />}</Link></div>
                <Row className='my-5 mx-0 text-center' xs={1} md={2} lg={3}>
                    {productList.map(product=>(
                        <Col key={product.id}>
                        <Card className='w-100' id='productCardTheme' style={{ width: '18rem', marginBottom:'2rem',padding:'0'}}>
                            <Link to={'/productDetail/' + product.id}><Card.Img variant="top" src={process.env.PUBLIC_URL + '/img/' + product.image} /></Link>
                            <Card.Body>
                                <Card.Title className='fw-bold'>{product.name}</Card.Title>
                                <Card.Text>
                                    <ui style={{ listStyle: 'none' }}>
                                        <li className='my-3'>Price:$ {product.price}</li>
                                    </ui>
                                </Card.Text>
                                <AddCartBtn productInfo={product} />
                            </Card.Body>
                        </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}
