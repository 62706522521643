import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import DarkThemeBtn from '../components/ThemeBtn'


export default function Header() {

    return (
        <>
            <Navbar className='py-5'>
                <Container>
                    <h1>J's Online Photography Shop</h1>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <DarkThemeBtn />
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar><hr/>
        </>
    )
}
