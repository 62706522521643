import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { ThemeContext } from '../ThemeContext'
import Container from 'react-bootstrap/Container'
import { IoChevronBack } from "react-icons/io5"
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

export default function ProductDetail() {
    const {themes} = useContext(ThemeContext)
    const params = useParams()
    const [productData, setProductData] = useState([])

    useEffect(()=>{
        fetch('https://joe-kaiwai.github.io/API/photography_api.json')
            .then(response => response.json())
            .then(data => setProductData(data.find((elements) => {
                return elements.id == params.id
            })))
    },[]
    )

    return (
        <div id={themes}>
            <Header/>
            <Link to='/'><Button variant="outline-success" className='ms-3 mb-4'><IoChevronBack /></Button></Link>
            <Container className='border rounded p-3 text-center mb-5'>
                <h3 className='py-5'>Product Detail</h3>
                <Row className=' d-flex align-items-center text-center' xm={1} lg={2}>
                    <Col><img src={process.env.PUBLIC_URL+'/img/'+productData.image} width={350}/></Col>

                    <Col id='detailWrapper'>
                        <h3 className='fw-bold py-3'>{productData.name}</h3>
                        <p>Price:$ {productData.price}</p>
                        <p class="fst-italic"><q>{productData.description}</q></p>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}
