import React, { useContext } from 'react'
import { ThemeContext } from '../ThemeContext'
import Switch from 'react-switch'




export default function ThemeBtn() {

    const { themes, toggleThemes } = useContext(ThemeContext)


    /* const darkStyle ={
        backgroundColor:'black',
        color:'white'
    } */

    return (
        <div className='d-flex align-items-center'>
            <label for='switchControl' id='themeIndexName' className='pe-2'>Dark Mode</label>
            <Switch onChange={toggleThemes} checked={themes === "dark"} uncheckedIcon={false} checkedIcon={false} id='switchControl' />
        </div>
    )
}
