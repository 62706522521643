import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import paymentMethod from '../PaymentMethod.json'
import { ThemeContext } from '../ThemeContext'
import { CartContext } from '../CartContext'
import { useNavigate } from 'react-router-dom'

export default function Payment() {
    const {themes} = useContext(ThemeContext)
    const {cartItems} = useContext(CartContext)
    const navigate = useNavigate()

    let grandTotal = cartItems.reduce((total,items)=>{
        return total += items.price * items.quantity
    },0)


    const [validated, setValidated] = useState(false)
    const [customerName, setCustomerName] = useState(null)
    const [customerPhone, setCustomerPhone] = useState(null)

    const handleChange = (e) =>{
        setCustomerName(e.target.value)
    }

    const handlePhone = (e) => {
        setCustomerPhone(e.target.value)
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true){
            window.localStorage.setItem('totalPrice', JSON.stringify(grandTotal))
            window.localStorage.setItem('name', JSON.stringify(customerName))
            window.localStorage.setItem('phone', JSON.stringify(customerPhone))
            navigate('/checkoutReview')
        }
        
    };

    return (
        <>
            <h3 className='text-center my-5 fw-bold'>Payment & Delivery Service</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row xs={1} md={2} className='d-flex align-items-center'>
                <Col>
                    <h5 className='fw-bold mb-5'>Payment Method</h5>
                    <div >{paymentMethod.map(element=>(
                        <Form.Check
                            inline={true}
                            type="radio"
                            label={<img className={themes === 'dark' ? " mb-3 me-2 img-thumbnail" : "mb-3 me-2"} src={process.env.PUBLIC_URL + '/img/' + element.image} width={100} />}
                            name="paymentMethod"
                            id={element.name}
                            value={element.name}
                            controlId="validationCustom01"
                            required
                            />))}

                        <Form.Group as={Row} className="mb-4">
                            <Form.Label column sm="3" className='fw-bold'>
                                Total Price:
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control id='darktThemeTextColor' name='totalPrice' plaintext readOnly Value={grandTotal} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Form.Label column sm="3" className='fw-bold'>
                                Your Name:
                            </Form.Label>
                            <Col sm="9">
                            <Form.Control placeholder="Joe" name='name' onChange={handleChange} required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide your name.
                            </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Form.Label column sm="3" className='fw-bold'>
                                Your Phone:
                            </Form.Label>
                            <Col sm="9">
                                    <Form.Control placeholder="1234 5678" name='phone' onChange={handlePhone} maxLength={8} minLength={8} onKeyPress={(event) =>{if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your Phone.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                            <Form.Label column sm={3} className='fw-bold'>
                                Card Number:
                            </Form.Label>
                            <Col sm={9}>
                                    <Form.Control placeholder="1234 5678 9012 3456" name='cardNumber' onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={16} minLength={16} required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide correct Card Number.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </div>
                </Col>

                <Col id='deliveryWrapper' className='text-center p-3'>
                    <h5 className='fw-bold py-3'>Delivery Service</h5>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
                        <Form.Label column sm={3} className='fw-bold'>
                            City
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Select disabled={true} name='city'><option className='text-center'>Hong Kong</option></Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
                        <Form.Label column sm={3} className='fw-bold'>
                            Area
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Select name='area'>
                                <option className='text-center'>Hong Kong Island</option>
                                <option className='text-center'>Kowloon</option>
                                <option className='text-center'>The New Territories</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
                        <Form.Label column sm={3} className='fw-bold'>
                            District
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control placeholder="1234 Main St" name='district' required />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            <div className='d-flex justify-content-center my-5'><Button variant="success" className='fw-bold' type='submit'>確認</Button></div>

            </Form>
        </>
    )
}
